// Import des outils Sass Bootstrap
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';

// Mixins
@import './core/config/mixins/mixins.scss';

// Config Rainbow
@import 'core/config/variables.scss';

// Config Spé
@import '../spe/config/variables.scss';

// Gestion des variables Bootstrap
@import 'bootstrap/scss/variables';

// Gestion des svg
@import './core/config/svgs.scss';

.popin-ie {
  position: relative;
  z-index: 2000;
  width: calc(100% / 3);
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 200px auto 0;

  @at-root {
    body {
      overflow: hidden;
    }
  }

  &__shadow {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1999;
  }

  .icon-ie {
    position: relative;
    width: 70px;
    height: 70px;
    box-shadow: none;
    background-color: var(--red);
    background-image: url(svg-bg-uri($svg-becareful, $white));

    &:after,
    &:before {
      content: '';
      width: 70px;
      height: 70px;
      position: absolute;
      display: block;
      background-color: rgba(255, 90, 115, 0.2);
      border-radius: 100%;
      transform: scale(1.25);
      animation: roundSmall 2s ease-in-out 5;
    }

    &:before {
      transform: scale(1.5);
      animation: roundLarge 2s ease-in-out 5;
    }

    &:hover {
      &:after,
      &:before {
        animation: roundSmall 2s ease-in-out infinite;
      }

      &:before {
        animation: roundLarge 2s ease-in-out infinite;
      }
    }

    svg {
      position: relative;
      z-index: 1;
      width: auto;
      height: 33px;
      fill: var(--white);
    }
  }

  h4 {
    text-align: center;
    margin: 20px 0 15px;
  }

  &__content {
    font-weight: 500;
    text-align: center;
  }

  &__links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--black);

    &:hover {
      color: var(--text-primary);

      img {
        filter: grayscale(1);
      }
    }

    img {
      margin-bottom: 7px;
    }
  }
}

@keyframes roundLarge {
  from {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}

@keyframes roundSmall {
  from {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(1.25);
  }
}
