@mixin animations($name, $duration: 0.2s, $type: null, $delay: null) {
  @at-root {
    body.loaded & {
      @if $type != null {
        animation: $name $duration $type;
      } @else {
        animation: $name $duration;
      }

      @if $delay != null {
        @for $i from 1 through $delay {
          &:nth-child(#{$i}) {
            animation-delay: #{($i + 1) * 0.1s};
          }
        }
      }
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0s !important;
    animation-delay: 0s !important;
  }
}

// Simple FadeIn
@keyframes simpleFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
// --

// FadeIn
@mixin fadeIn($scroll, $duration) {
  @if $scroll==scroll {
    opacity: 0;

    &[data-animation-state='active'] {
      animation: fadeIn #{$duration}s forwards;
    }
  } @else {
    opacity: 0;
    animation: fadeIn #{$duration}s forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
// --

// SlideInRight
@mixin slideInRight($scroll, $duration) {
  @if $scroll==scroll {
    opacity: 0;

    &[data-animation-state='active'] {
      animation: slideInRight #{$duration}s forwards;
    }
  } @else {
    animation: slideInRight #{$duration}s forwards;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(1000px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
// --

// SlideInLeft
@mixin slideInLeft($scroll, $duration) {
  @if $scroll==scroll {
    opacity: 0;

    &[data-animation-state='active'] {
      animation: slideInLeft #{$duration}s forwards;
    }
  } @else {
    animation: slideInLeft #{$duration}s forwards;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-1000px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin underline {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0;
    background: var(--primary);
    transition: 0.3s forwards;
  }

  &:hover,
  &:focus {
    &:after {
      width: 100%;
      transition: 0.3s forwards;
    }
  }
}

// Animation à include, si c'est un lien externe
@mixin underlineBlank {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0;
    background: var(--primary);
    transition: 0.3s forwards;
  }

  &:hover,
  &:focus {
    &:before {
      width: 100%;
      transition: 0.3s forwards;
    }
  }
}

// --

// Hi Pop-up - Carto
@keyframes hiPopup {
  from {
    opacity: 0;
    right: 0;
  }

  to {
    opacity: 1;
    right: 3vw;
  }
}
// --

// Bye Pop-up
@keyframes byePopup {
  from {
    opacity: 1;
    right: 3vw;
  }

  to {
    opacity: 0;
    right: 0;
    display: none;
  }
}
